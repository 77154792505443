/* ------------------------------------ *\
		tools.typography-mixins
\* ------------------------------------ */

@mixin heading--xl {
	font-family: var(--font-heading);
	font-weight: var(--font-weight--black);
	font-size: var(--font-size--3xl);
	line-height: var(--heading--line-height);
	-ms-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
}

@mixin heading--lg {
	font-family: var(--font-heading);
	font-weight: var(--font-weight--black);
	font-size: var(--font-size--2xl);
	line-height: var(--heading--line-height);
	-ms-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
}

@mixin heading--md {
	font-family: var(--font-heading);
	font-weight: var(--font-weight--semibold);
	font-size: var(--font-size--xl);
	line-height: var(--heading--line-height);
}

@mixin heading--sm {
	font-family: var(--font-heading);
	font-weight: var(--font-weight--semibold);
	font-size: var(--font-size--lg);
	line-height: var(--heading--line-height);
}

@mixin heading--xs {
	font-family: var(--font-heading);
	font-weight: var(--font-weight--semibold);
	font-size: var(--font-size--md);
	line-height: var(--heading--line-height);
}

@mixin manchet {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--normal);
	font-size: var(--font-size--xl);
	line-height: 1.5;
}

@mixin paragraph {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--normal);
	font-size: var(--font-size--md);
}

@mixin text-link--md {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--normal);
	font-size: var(--font-size--md);
}

@mixin text-link--sm {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--normal);
	font-size: var(--font-size--xs);
}

@mixin formfield-label {
	color: currentColor;
	width: auto;
	cursor: pointer;
}

@mixin error-message {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--normal);
	font-size: var(--font-size--md);
	color: var(--color-error);
}

@mixin text-fade {
	mask: linear-gradient(to bottom, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 100%);
}
