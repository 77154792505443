@import "../../../styles/2-tools/tools.typography-mixins.scss";

.LinkList {
	color: var(--color-white);

	&___black {
		color: var(--color-black);
	}
}

.LinkList_heading {
	color: currentColor;
	@include heading--sm;
	margin-bottom: var(--spacing--sm);
}

.LinkList_list {
	list-style: none;
}
.LinkList_listItem {
	margin: var(--spacing--md) 0;
	color: currentColor;
}

.LinkList_link {
	position: relative;
	margin-bottom: var(--spacing--xs);
	padding: 0 1rem 0 2rem;
	text-decoration: none;
	display: inline-block;
	color: currentColor;
	outline: none;
	font-size: var(--font-size--md);

	&:hover,
	&:focus {
		color: currentColor;
		text-decoration: none;
		background: var(--color-primary--60);

		:global(.sop-app) & {
			color: var(--color-white);
		}

		&::before {
			left: var(--spacing--xs);
		}
	}

	&::before {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		top: 50%;
		width: 8px;
		height: 8px;
		border: solid currentColor;
		border-width: 2px 2px 0 0;
		transform: translateY(-50%) rotate(45deg);
		transition: all 0.3s;
	}
}
