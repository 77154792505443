@import "../../../styles/2-tools/tools.media-query.scss";

.LinkLists {
	display: grid;
	grid-template-rows: 1fr;
	width: 100%;
	@include mq("sm", max) {
		grid-template-columns: 1fr;
	}
	@include mq("sm") {
		grid-template-columns: 1fr 1fr;
	}
	@include mq("md") {
		grid-template-columns: 1fr 1fr 1fr;
	}
	@include mq("lg") {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
}

.LinkLists_wrapper {
	margin-bottom: var(--spacing--2xl);
}

.LinkLists_list {
	@include mq("sm", max) {
		&:not(:last-child) {
			margin-bottom: var(--spacing--xl);
		}
	}
	@include mq("sm") {
		&:not(:nth-last-child(-n + 2)) {
			margin-bottom: var(--spacing--xl);
		}
	}
}
